import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/abdullatieffathoni/Code/javascript/tds-web/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const DoDontExample = makeShortcode("DoDontExample");
const Video = makeShortcode("Video");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
      <p>{`The `}<inlineCode parentName="p">{`<DoDontExample>`}</inlineCode>{` component will generally need to be placed inside `}<inlineCode parentName="p">{`<Row>`}</inlineCode>{` and `}<inlineCode parentName="p">{`<Column>`}</inlineCode>{` components to align them to the grid at the correct size.`}</p>
    </PageDescription>
    <h2>{`Example`}</h2>
    <Row mdxType="Row">
      <Column colMd={4} colLg={4} mdxType="Column">
        <h4>{`Image`}</h4>
        <DoDontExample type="do" captionTitle="Caption title" caption="Caption" mdxType="DoDontExample">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1088px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "100%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsSAAALEgHS3X78AAABKUlEQVQ4y81Ux46FMAzk/78PidsDLvTeu1djKchAltf2sJGGJCYzuOAYeZ5TWZZUFAXP38LAo67rP4MBz7CoqorxroDiKeweSqMM4UzQQfIPgk3TUN/3NI4jDcOwAzYd8A4cGdlBEIdQpDAMKcsyCoKAZ4xt23ao/TRNLPqrYNd1fKBtW/YShHmembyu6wWwgyPDvoSMNQoFITWkd2dPwZEah98mSRJyHIfheR5FUcThnwG7egeOtihAHMdkWRaZpkmu65Lv+ywsAZv9eJBt2/tHb6uM/GGN/J1DRN4wkDcU8DZkbCCGgyDIYuhEVQ7BuRXEPk1TrvJdQV4WRKgQW5ZF+6tIPBVEPlSXQFh1w8ce3vWq7lLArPr9LcFXL4dL6316B/5/DyV+AJYzBVGK7r7sAAAAAElFTkSuQmCC')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Alt text",
              "title": "Alt text",
              "src": "/static/b0f40c28c5434c5a2816ef779316fdeb/377f4/light-theme.png",
              "srcSet": ["/static/b0f40c28c5434c5a2816ef779316fdeb/7fc1e/light-theme.png 288w", "/static/b0f40c28c5434c5a2816ef779316fdeb/a5df1/light-theme.png 576w", "/static/b0f40c28c5434c5a2816ef779316fdeb/377f4/light-theme.png 1088w"],
              "sizes": "(max-width: 1088px) 100vw, 1088px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span>
        </DoDontExample>
      </Column>
      <Column colMd={4} colLg={4} mdxType="Column">
        <h4>{`Text`}</h4>
        <DoDontExample type="dont" aspectRatio="1:1" color="dark" captionTitle="Caption title" caption="Caption" text="This is some text" mdxType="DoDontExample" />
      </Column>
    </Row>
    <Row mdxType="Row">
      <Column colLg={8} mdxType="Column">
        <h4>{`Video`}</h4>
        <DoDontExample type="do" caption="Caption" captionTitle="Caption title" mdxType="DoDontExample">
          <Video title="Video example" vimeoId="310583077" mdxType="Video" />
        </DoDontExample>
      </Column>
    </Row>
    <h2>{`Code`}</h2>
    <h4>{`Image`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "path=components/DoDontExample/DoDontExample.js src=https://github.com/carbon-design-system/gatsby-theme-carbon/tree/master/packages/gatsby-theme-carbon/src/components/DoDontExample",
        "path": "components/DoDontExample/DoDontExample.js",
        "src": "https://github.com/carbon-design-system/gatsby-theme-carbon/tree/master/packages/gatsby-theme-carbon/src/components/DoDontExample"
      }}>{`<DoDontExample type="do" captionTitle="Caption title" caption="Caption">
  ![Alt text](images/light-theme.png)
</DoDontExample>
`}</code></pre>
    <h4>{`Text`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "path=components/DoDontExample/DoDontExample.js src=https://github.com/carbon-design-system/gatsby-theme-carbon/tree/master/packages/gatsby-theme-carbon/src/components/DoDontExample",
        "path": "components/DoDontExample/DoDontExample.js",
        "src": "https://github.com/carbon-design-system/gatsby-theme-carbon/tree/master/packages/gatsby-theme-carbon/src/components/DoDontExample"
      }}>{`<DoDontExample
  type="dont"
  aspectRatio="1:1"
  color="dark"
  captionTitle="Caption title"
  caption="Caption"
  text="This is some text"
/>
`}</code></pre>
    <h4>{`Video`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "path=components/DoDontExample/DoDontExample.js src=https://github.com/carbon-design-system/gatsby-theme-carbon/tree/master/packages/gatsby-theme-carbon/src/components/DoDontExample",
        "path": "components/DoDontExample/DoDontExample.js",
        "src": "https://github.com/carbon-design-system/gatsby-theme-carbon/tree/master/packages/gatsby-theme-carbon/src/components/DoDontExample"
      }}>{`<DoDontExample type="do" caption="Caption" captionTitle="Caption title">
  <Video vimeoId="310583077" />
</DoDontExample>
`}</code></pre>
    <h2>{`Props`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`property`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`propType`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`required`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`default`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`children`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`node`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`child node, expects a markdown image or `}<inlineCode parentName="td">{`<Video>`}</inlineCode>{` component`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`text`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`text to display inside the component instead of an image or video`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`caption`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`caption`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`captionTitle`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`caption title`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`type`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`dont`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`set to `}<inlineCode parentName="td">{`do`}</inlineCode>{` to show green check, otherwise shows pink X`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`light`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`set to `}<inlineCode parentName="td">{`dark`}</inlineCode>{` for dark background card`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`aspectRatio`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`set to `}<inlineCode parentName="td">{`1:1`}</inlineCode>{` to force square example `}<br /><em parentName="td">{`(We welcome `}<a parentName="em" {...{
                "href": "https://github.com/carbon-design-system/gatsby-theme-carbon/tree/master/packages/gatsby-theme-carbon/src/components/DoDontExample"
              }}>{`contributions`}</a>{` to add additional aspect ratio options)`}</em></td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      